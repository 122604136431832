@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&family=Courgette&family=Montserrat:wght@300&family=Nunito+Sans&family=Poppins:wght@500&family=Raleway:wght@600&family=Roboto&family=Manrope:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  background-color: #000000;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
